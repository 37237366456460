.calculateMarginContainer {
  background-color: #ffffff;
  border: 2px solid #007bff;
  border-radius: 10px;
  padding: 20px;
  margin: 10px auto;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.totalInvestedAnalytic {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
}

.marginValuesContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
}

.relativeMarginAnalytic {
  font-weight: bold;
  color: #28a745;
}

.absoluteMarginAnalytic {
  font-weight: bold;
  color: #dc3545;
}

.marginRangeInput {
  width: 100%;
  margin-bottom: 10px;
}

.appliedMarginQuantityContainer {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
}