.editContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 600px; 
  background-color: #ffffff;
  padding: 10px; 
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  margin-bottom: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 60px);
}

.editProductContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  padding: 10px; 
  overflow: hidden; 
}

.inputFormEdit {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px; 
  margin: 5px 0; 
}

.inputFormEdit input {
  padding: 8px; 
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.inputFormEdit input:focus {
  border: 1px solid #007bff;
  box-shadow: 0 0 6px rgba(0, 123, 255, 0.5);
  outline: none;
}

.buttonSubmit {
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 8px 16px; 
  margin: 5px 0; 
  cursor: pointer;
  font-size: 12px;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 300px; 
}

.buttonSubmit:hover {
  background-color: #19692c;
}

.productDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}