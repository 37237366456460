.myProductsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F5F5F5;
  padding: 20px;
  min-height: 100vh;
}

.searchContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.searchInput {
  padding: 10px;
  font-size: 16px;
  width: 80%;
  max-width: 500px;
  border-radius: 8px;
  border: 2px solid black;
  background-color: #ffffff;
}

.myProducts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;
}

.productBg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #E8DCCA;
  border: 2px solid black;
  border-radius: 8px;
  padding: 10px;
  font-size: small;
  font-weight: 500;
  width: 150px;
  transition: all 0.3s ease;
  position: relative;
}

.productBg:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.productInfoContainer {
  background-color: rgb(240, 181, 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
}

.selectedProduct {
  border: 2px solid #007bff;
  box-shadow: 0px 0px 5px #007bff;
}

.productLink {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
  margin-top: 10px;
  transition: color 0.3s ease;
}

.productLink:hover {
  color: #0056b3;
}

.deleteButton {
  position: absolute;
  display: flex;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  justify-content: center;
  align-items: center;
}

.deleteButton:hover {
  background-color: darkred;
}

.cont {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.todo {
  margin-top: 200px;
}
