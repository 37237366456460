.cambiarPlanContainer {
  margin: 10px 10px;
}

.cambiarPlanButton {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cambiarPlanButton:hover {
  background-color: #0056b3;
}

.modalPlanContent {
  width: 90%;
  max-width: 500px;
  margin: auto;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

.modalPlanOverlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.modalCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #888;
}

.planesContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.planCard {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.planCard h3 {
  margin-bottom: 10px;
}

.planCard.bronce {
  background-color: #cd7f32;
  color: white;
  border: 1px solid #ad6d29;
}

.planCard.plata {
  background-color: #c0c0c0;
  color: black;
  border: 1px solid #a1a1a1;
}

.planCard.oro {
  background-color: #ffd700;
  color: black;
  border: 1px solid #e0b800;
}

.payButton {
  background-color: #28a745;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payButton:hover {
  background-color: #218838;
}

.planCard.extraProducts {
  background-color: #e0f7fa;
  border: 1px solid #00acc1;
}