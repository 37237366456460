.ticketFunctions {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 50px;
  flex-direction: column;
  align-items: center;
}

.goToTicketsStats {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.goToTicketsStats:hover {
  background-color: #0056b3;
}

@media (min-width: 768px) {
  .ticketFunctions {
    flex-direction: row;
    align-items: flex-start;
  }

  .goToTicketsStats {
    position: fixed;
    right: 20px;
    top: 100px;
  }
}