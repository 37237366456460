.containerUserSucursal {
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.button {
  margin: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

.qrCode {
  width: 100px;
  position: fixed;
  left: 10px;
  top: 10px;
  z-index: 5;
}

.sucursalCard {
  width: 80%;
  margin-top: 20px;
}