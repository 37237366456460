.registerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 110vh;
    justify-content: center;
    align-items: center;
    /* background-color: #ffe3b3;  */
    color: black;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  .termsContainerInactive{
    display: flex;
    justify-content: space-between;
    padding: 5px;
    /* background-color: rgb(225, 172, 172); */

    width: 100%;
  }
  .termsContainerActive{
    display: flex;
    justify-content: space-between;
    padding: 5px;
    /* background-color: rgb(150, 207, 150); */

    width: 100%;
  }
  .link{
    cursor: pointer;
  }
  .link:hover{
    color: blue;
    
  }
  .termsContainer{
    display: flex;
    padding: 5px;
    /* background-color: #f0a500; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .registerFormContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 350px;
  }
  
  .registerInputGroup {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 100%;
  }
  
  .registerInputGroup input {
    padding: 10px;
    font-size: medium;
    border: 2px solid black;
    border-radius: 10px;
  }
  
  .registerButton {
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
    font-weight: 600;
    background-color: #f0a500;
    color: white;
    border: 2px solid black;
  }
  
  .registerButton:hover {
    background-color: #ffb84d;
  }
  
  .registerErrorMessage {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .registerLink {
    color: #f0a500;
    text-decoration: none;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  
  .registerLink:hover {
    text-decoration: underline;
  }