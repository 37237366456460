.superUserContainer {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f7f7f7;
  min-height: 100vh;
}

.superUserTitle {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.formsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

.debugInfo {
  margin-top: 40px;
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.debugInfo h2 {
  margin: 0;
  margin-bottom: 10px;
  color: #555;
}

.debugInfo pre {
  font-size: 12px;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
}

.accessDenied {
  font-size: 1.5rem;
  color: red;
  margin-top: 40px;
}