.userNavThumb {
  display: flex;
  /* background-color: #E8DCCA; */
  width: 80px;
  height: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  color: white;
  position: relative;
  border-radius: 10px;
  transition: all 0.3s ease;
  width: 15%;
}

.userNavThumb:hover {
  color: black;
  background-color: #E8DCCA;
  width: 20%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  /* transform: scale(1.05); */
}

.userNavImage {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
  filter: invert();
}

.userNavThumb div {
  font-family: "Arial", sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

@keyframes scalingup {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes scalingdown {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 800px) {
  
  .userNavThumb{
    display: none;
    background-color: red;
  }
  .userNavImage >img{
    display: none;
    background-color: red;
  }
  .userNavName{
    display: none;
  }
}