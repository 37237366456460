:root {
  --primary-color: #1a73e8; 
  --secondary-color: #ff7043; 
  --accent-color: #34a853;
  --background-color: #f9fafb; 
  --text-primary: #202124;
  --text-secondary: #5f6368;
  --error-color: #ff5252; 
}

body {
  margin: 0;
  font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", sans-serif;
  color: var(--text-primary);
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
  color: var(--text-primary);
  margin: 0;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.button-primary {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-primary:hover {
  background-color: #1558b2;
}

.button-secondary {
  background-color: var(--secondary-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-secondary:hover {
  background-color: #cc5b36;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--text-secondary);
  border-radius: 4px;
  background-color: #fff;
}

.label-field {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}
