.createProductContainer {
  background-color: #ffffff;
  border: 2px solid black;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 15px;
  text-align: center;
  max-width: 800px;
}

.createInput {
  margin: 10px;
  padding: 10px;
  font-size: 1em;
  border-radius: 8px;
  border: 2px solid black;
  width: 80%;
}

.createButton {
  background-color: #ffd449;
  border: 2px solid black;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px 20px;
  margin: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.createButton:hover {
  background-color: #8a0202;
  color: white;
  transform: scale(1.05);
}

.pError {
  color: red;
  font-size: 14px;
  margin: 5px;
}