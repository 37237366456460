.sucursalCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 20px auto;
}

.sucursalTitle {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.sucursalImage {
  width: 100%;
  max-width: 200px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.bothPrivateAndPublicContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.PublicProductsContainer,
.PrivateProductsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
}

.PublicProductContainer,
.PrivateProductContainer {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  width: 100%;
  transition: transform 0.3s;
}

.PublicProductContainer:hover,
.PrivateProductContainer:hover {
  transform: translateY(-5px);
}

.PublicProductName,
.PrivateProductName {
  font-size: 18px;
  color: #333;
}

.PublicProductImage,
.PrivateProductImage {
  width: 100%;
  max-width: 150px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.PublicProductPrice,
.PrivateProductPrice {
  font-size: 16px;
  color: #007bff;
}

button {
  padding: 8px 12px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.ProductButtons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}