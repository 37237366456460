body {
  background-color: #F5F5F5;
}

.uploadProductContainer {
  background-color: #ffffff;
  border: 2px solid black;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: auto;
  margin-top: 50px;
}

.toolWrapper {
  display: flex;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  flex-direction: column;
}

.uploadTitle {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #8a0202;
}

.registerPromptContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 80vh;
  background-color: #F5F5F5;
}

.registerPrompt {
  font-size: 2em;
  cursor: pointer;
  color: #8a0202;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 2px solid black;
  border-radius: 8px;
  background-color: #ffd449;
  padding: 15px 30px;
}

.registerPrompt:hover {
  color: white;
  background-color: #8a0202;
  transform: scale(1.05);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}