.navbar {
  background-color: #2A3F5D;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  height: 20px;
  top: 0;
  z-index: 1000;
}

.navbarContainer {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-around;
}

.menuIcon {
  height: 25px;
  cursor: pointer;
  margin-right: 10px;
  
}
.menuIcon:hover {
  padding: 2px;
  filter: invert();
  background-color: #2A3F5D;
}

.navLink {
  color: #E8DCCA;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  position: relative;
}

.navLink.active,
.navLink:hover {
  background-color: #FFA07A;
  color: #8a0202;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.navLink::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s;
}

.navLink.active::after,
.navLink:hover::after {
  background-color: #8a0202;
}

@media (max-width: 600px) {
  .navbarContainer {
    flex-wrap: wrap;
    justify-content: center;
  }

  .navLink {
    font-size: 12px;
    padding: 8px 10px;
  }
}