.sucursalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 50px auto;
}

.sucursalTitle {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.sucursalProducts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.sucursalProductCard {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.sucursalProductCard:hover {
  transform: translateY(-5px);
}

.sucursalImage {
  width: 100%;
  max-width: 200px;
  border-radius: 8px;
  margin-bottom: 20px;
}