.createSucursalFormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
}

.createSucursalForm {
  width: 100%;
  max-width: 500px;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.createSucursalFormGroupContainer {
  margin-bottom: 20px;
}

.createSucursalFormGroupContainer label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #333;
}

.createSucursalFormGroupContainer input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.sucursalImage {
  width: 100%;
  height: auto;
  margin: 20px 0;
  border-radius: 4px;
  object-fit: cover;
}

.createSucursalButton {
  width: 100%;
  padding: 15px;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.createSucursalButton:hover {
  background-color: #218838;
}