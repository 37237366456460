.uploadTicketsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
}

.uploadButton,
.loadButton,
.postButton {
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin: 10px;
}

.uploadButton:hover,
.loadButton:hover,
.postButton:hover {
  background-color: #19692c;
}

.uploadInput {
  margin: 20px 0;
}

.ticketCard {
  border: 2px solid black;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  background-color: #ffffff;
  width: 100%;
  max-width: 600px;
}

.ticketCard h3,
.ticketCard h4 {
  margin: 5px 0;
}

.ticketProduct {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.ticketProduct h6 {
  color: red;
  margin: 0;
}

.ticketProduct span {
  margin-left: 10px;
}