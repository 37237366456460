.convertContainer {
  background-color: #ffffff;
  border: 2px solid black;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 15px;
  text-align: center;
  max-width: 800px;
}

.convertInput {
  margin: 10px;
  padding: 10px;
  font-size: 1em;
  border-radius: 8px;
  border: 2px solid black;
}

.convertButtonWrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.convertButton {
  background-color: #ffd449;
  border: 2px solid black;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px 20px;
  margin: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.convertButton:hover {
  background-color: #8a0202;
  color: white;
  transform: scale(1.05);
}

.productsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px 0;
}

.productContainer {
  background-color: #ffffff;
  border: 2px solid black;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin: 10px;
  text-align: center;
}

.convertPagination {
  display: flex;
  justify-content: space-between;
}

.convertNavButton {
  background-color: #ffd449;
  border: 2px solid black;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px 20px;
  margin: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.convertNavButton:hover {
  background-color: #8a0202;
  color: white;
  transform: scale(1.05);
}