.carrouselBg{
    background-color: #FFD449;
    height: 400px;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* object-fit:scale-down; */
    /* object-fit: contain; */
    /* padding: 12%; */
}
.advImg{
    position: relative;
    /* width: 35%; */
    max-height: 400px;
    max-width: 100%;
    /* object-fit: fill; */
}
