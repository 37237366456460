.productDetailContainer {
  background-color: #ffffff;
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 50px auto;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.favImgStateActive{
  max-width: 50px;
  border: black solid 2px;
  border-radius: 10px;
  background-color: white;
  filter: invert();
  padding: 7PX;
}
.favImgStateInactive{
  max-width: 50px;
  padding: 7PX;
  border: black solid 2px;
  border-radius: 10px;
  background-color: white;
  /* filter: invert(); */
}
.productDetailTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.productDetailItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.productDetailLabel {
  font-weight: bold;
  color: #007bff;
}

.productDetailValue {
  color: #333;
}

.productDetailBarcode {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.printButton {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.printButton:hover {
  background-color: #0056b3;
}

.deleteProductButton {
  background-color: red;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.deleteProductButton:hover {
  background-color: rgb(126, 13, 13);
}