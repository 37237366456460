.loginPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 115vh;
  background-color: #F5F5F5;
  color: black;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.loginPageLogo {
  width: 135px;
  height: 135px;
  border-radius: 50%;
  border: 5px solid #ffffff; 
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
  object-fit: cover;
  margin-top: 10px;
  margin-bottom: 20px;
}

.loginPageInputGroup {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}

.loginPageInputField {
  padding: 10px;
  font-size: medium;
  border-radius: 8px;
  border: 2px solid black;
  width: 100%;
  box-sizing: border-box;
}

.loginPageInputField:focus {
  outline: none;
  border-color: #f39c12;
  box-shadow: 0 0 8px rgba(243, 156, 18, 0.6);
}

.loginPageFormContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.loginPageLoginButton,
.loginPageRegisterButton {
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  font-weight: 600;
  border: 2px solid black;
  margin: 10px 0;
  background-color: #f39c12;
  color: white;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.loginPageLoginButton:hover,
.loginPageRegisterButton:hover {
  background-color: #e67e22;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.loginPageErrorMessage {
  color: #dc3545;
  font-size: 16px;
  margin-top: 15px;
  padding: 10px 15px;
  border: 2px solid #dc3545;
  border-radius: 8px;
  background-color: rgba(220, 53, 69, 0.1);
  text-align: center;
}