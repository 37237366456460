.modal-content {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 20%;
  max-width: 500px;
  margin: 100px auto;
  position: relative;
}

.modal-title {
  text-align: center;
  color: #007bff;
  margin-bottom: 10px;
}

.modal-question {
  margin: 10px 0;
  text-align: center;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.confirmButton {
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirmButton:hover {
  background-color: #218838;
}

.cancelButton {
  background-color: #dc3545;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancelButton:hover {
  background-color: #a71d2a;
}