.homePage {
  background-color: #F5F5F5;

  padding: 20px;
  min-height: 100vh;
}

.homeToolContainer {
  background-color: #ffffff;
  cursor: pointer;
  margin: 15px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 200px;
  height: 150px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeToolContainer:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.homeBannerImg {
  width: 130px;
  height: 130px;
}

.homeCarrousel {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  left: 0%;
  width: 100%;
  display: grid;
  grid-gap: 10px;
  padding: 10px;
}

.homeToolsWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.promosHome {
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 10px;
  padding: 10px;
  margin-bottom: 20px;
}

.promoContainer {
  border: 2px solid black;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
}

.promoImg {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.departamentContainer {
  border: 2px solid black;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  max-height: 140px;
  overflow-y: auto;
}

.departamentTitle {
  margin: 0;
  padding: 0;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
}

.separatorLine {
  background-color: black;
  height: 1px;
  margin-bottom: 10px;
  width: 100%;
}

.filteredProductContainer {
  border: 2px solid black;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  margin-bottom: 10px;
}

.filteredProductContainer h3,
.filteredProductContainer h4 {
  margin: 0;
  padding: 0;
}

.homeDepartmentsWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 10px;
}

@media (max-width: 800px) {
  .homeToolsWrapper {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .homeCarrousel {
    height: auto; /* Cambiado de 600px a auto para que se ajuste automáticamente */
    position: relative;
    left: 0;
  }

  .promoContainer {
    flex-direction: column;
  }
}