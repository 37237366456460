.createUserFormContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: rgb(233, 163, 0);
  min-height: 100vh;
}

.createUserForm {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 500px;
  width: 100%;
}

.createUserFormGroupContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.createUserFormGroupContainer label {
  margin-bottom: 5px;
  font-weight: bold;
}

.createUserFormGroupContainer input,
.createUserFormGroupContainer select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  transition: border 0.3s;
}

.createUserFormGroupContainer input:focus,
.createUserFormGroupContainer select:focus {
  border: 1px solid #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.createUserButton {
  margin-top: 20px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.createUserButton:hover {
  background-color: #0056b3;
}