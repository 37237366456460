.crearUserContainer {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 20px auto;
}

.crearUserForm {
  display: flex;
  flex-direction: column;
}

.formGroup {
  margin-bottom: 15px;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.formGroup input,
.formGroup select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;
}

.submitButton {
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #0056b3;
}

.errorMessage {
  color: red;
  margin-top: 10px;
}