.userDetailContainer {
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.userDetailInfo {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.userDetailImage {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  object-fit: cover;
  border: 5px solid #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.logoutButton {
  height: 40px;
  width: 125px;
  background-color: #b04e3c;
  color: #ffffff;
  font-size: 13px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logoutButton:hover {
  background-color: #ff1803;
}

.editButton {
  background-color: #007bff;
  margin: 10px;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.editButton:hover {
  background-color: #0056b3;
}

/* Estilos actualizados para el modal */
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.modalContent {
  width: 90%;
  max-width: 500px;
  margin: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  max-height: 90vh; /* Para asegurar que no se corte el contenido */
  overflow-y: auto; /* Para permitir el desplazamiento si el contenido es demasiado largo */
}

.modalCloseButton {
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #888;
}

.saveButton {
  background-color: #28a745;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.saveButton:hover {
  background-color: #218838;
}

.inputField {
  margin-left: 7px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 30%;
}

.errorMessage {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}